$primaryColor: #1155B0;

.admin-header.ant-layout-header {
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;

    .ant-menu-ipadama {
        box-shadow: none;
        border-bottom-width: 0;
        line-height: 50px;

        .ant-menu-item {
            text-transform: capitalize;
            color: #111;
            font-weight: 600;
            font-size: 0.9rem;
            // background-color: #1c1c1c;

            &:hover {
                color: $primaryColor;
            }

            a {
                color: $primaryColor;
                font-weight: 600;

                &:hover {
                    color: $primaryColor;
                }
            }

            &.ant-menu-item-selected {
                border-bottom-color: transparent;
                color: #707070;
                font-weight: 600;

                a {
                    color: $primaryColor;
                    font-weight: 600;
                }
            }

            a,
            a:hover {
                cursor: pointer;
                text-align: center;

                &::after {
                    display: block;
                    content: attr(data-menu-label);
                    font-weight: bold;
                    height: 1px;
                    color: transparent;
                    overflow: hidden;
                    visibility: hidden;
                }
            }
        }

        &.right {
            float: right;

            .ant-menu-item {
                border-bottom-color: transparent;
            }
        }

        &.left {
            float: left;

            .ant-menu-item {
                border-bottom-color: transparent;
            }
        }
    }
}